const updateCheckbox = (selector) => {
  const inputs = document.querySelectorAll(selector);
  const chckedInputs = document.querySelectorAll(`${selector}:checked`);
  const buttonElem = inputs.length ? inputs[0].closest('.filtering__option-box').querySelector('.filtering__toggle-option-button') : null;
  
  if(chckedInputs.length){
  
    if(buttonElem){
      buttonElem.innerHTML = chckedInputs[0].closest('label').textContent;
    }

  } else  {
    const emptyInput = document.querySelector(`${selector}[value=""]`);
    if(emptyInput){
      emptyInput.checked = true;
    }
    if(buttonElem){
      buttonElem.innerHTML = emptyInput.closest('label').textContent;
    }
  }

  // const conditionsForm = document.querySelector('.conditions-form');
  // const data = new FormData(conditionsForm);

  // const shape = data.get('c_shapes') || '';
  // const scent = data.get('c_scents') || '';
  // const color = data.get('c_colors') || '';
  // const size = data.get('c_sizes') || '';
  // const material = data.get('c_materials') || '';

  

  inputs.forEach(input => {
    //input.value = input.checked ? 'on' : 'off';
    input.addEventListener('change', (e) => {
      buttonElem.innerHTML = e.currentTarget.closest('label').textContent;
    });
  });
}

export function SearchBox() {

  const searchBox = document.querySelector('.search-section');
  if(searchBox){

    const activeTab = searchBox.querySelector('.tab__button--active');

    if(!activeTab){
      searchBox.querySelector('.tab__button:nth-child(1)').classList.add('tab__button--active');
      searchBox.querySelector('.tab__content:nth-child(1)').classList.add('tab__content--active');
    }

    const tabs = searchBox.querySelectorAll('.tab__button');

    tabs.forEach(tab => {
      tab.addEventListener('click', (e) => {
        const index = Array.from(tab.parentNode.children).indexOf(tab);
        const contents = searchBox.querySelectorAll('.tab__content');
        const buttons = searchBox.querySelectorAll('.tab__button');
        contents.forEach(content => content.classList.remove('tab__content--active'));
        buttons.forEach(button => button.classList.remove('tab__button--active'));
        contents[index].classList.add('tab__content--active');
        buttons[index].classList.add('tab__button--active');
      });
    });

    const openButton = searchBox.querySelector('.mobile-search-nav__open-button');
    const mobileSearchBlocks = searchBox.querySelector('.mobile-search-nav__blocks');
    
    openButton.addEventListener('click', (e) => {
      e.preventDefault();
      mobileSearchBlocks.classList.toggle('opened');
    });

    // const conditionsForm = searchBox.querySelector('.conditions-form');
    // conditionsForm.addEventListener('submit', (e) => {
    //   e.preventDefault();
    //   const formData = new FormData(e.currentTarget);
    //   const shape = formData.get('shape') || '';
    //   const scent = formData.get('scent') || '';
    //   const color = formData.get('color') || '';
    //   const size = formData.get('size') || '';
    //   const materials = formData.get('materials') || '';
    //   const queryAry = [];
    //   if(shape) queryAry.push(shape);
    //   if(scent) queryAry.push(scent);
    //   if(color) queryAry.push(color);
    //   if(size) queryAry.push(size);
    //   if(materials) queryAry.push(materials);

    //   const query = queryAry.join(',');
    //   window.location.href = `${window.theme.blogURL}/products?conditions=${query}`;
    // });

    const names = ['c_shapes', 'c_scents', 'c_colors', 'c_sizes', 'c_materials'];

    names.forEach(name => {
      updateCheckbox(`.conditions-form input[name="${name}"]`);
    });

    const keywordForm = searchBox.querySelectorAll('.keyword-form');
    keywordForm.forEach(form => {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const keyword = formData.get('s') || '';
        window.location.href = `${window.theme.blogURL}/products?s=${keyword}`;
      });
    });
    

  }
}