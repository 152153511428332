import Swiper from 'swiper';
import 'swiper/css';


export function Product() {

  const productPage = document.querySelector('.product-single-page');
  const slides = document.querySelectorAll('.swiper-slide');
  const thumbnails = document.querySelectorAll('.product__media-thumbnail button');
  let slider;

  const changeSlide = (e) => {
    e.preventDefault();

    thumbnails.forEach(thumbnail => {
      thumbnail.classList.remove('active');
    });

    e.currentTarget.classList.add('active');

    const currentTarget = e.currentTarget.closest('.product__media-thumbnail');
    const parent = currentTarget.parentNode;
    const children = Array.prototype.slice.call(parent.children);
    const index = children.indexOf(currentTarget);
    
    if (index !== -1 && slider) {
      slider.slideTo(index);
    }
  }

  if(slides.length > 1){

    slider = new Swiper('.swiper', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      on: {
        slideChange: function () {
          thumbnails.forEach(thumbnail => {
            thumbnail.classList.remove('active');
          });
          thumbnails[this.realIndex].classList.add('active');
        },
      },
    });

    thumbnails.forEach(thumbnail => {
      thumbnail.addEventListener('click', changeSlide);
    });
  }


  const firstTn = document.querySelector('.product__media-thumbnail:nth-child(1) button');

  if(firstTn){
    firstTn.classList.add('active');
  }



  const toggleAccordion = (e) => {
    const content = e.target.nextElementSibling;
    e.target.classList.toggle('opened');
    content.classList.toggle('opened');
  }

  const share = async ()=>{
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: 'このページを共有する',
          url: window.location.href
        });
      } catch (error) {

      }
    }
  }

  if(productPage){
    if(navigator.share){
      const shareBtn = document.querySelector('.share-icon');
      shareBtn.classList.add('visible');
      shareBtn.addEventListener('click', share);
    }

    const accordionBtns = document.querySelectorAll('.accordion__toggle-button');
    accordionBtns.forEach(btn => {
      btn.addEventListener('click', toggleAccordion);
    });

    const productInfoButtons = document.querySelectorAll('.product-info__title');
    productInfoButtons.forEach((button, index) => {
      button.addEventListener('click', (e) => {
        const parent = e.target.closest('.product-info__item');
        parent.classList.toggle('opened');
      });
    });

  }




}