import $ from 'jquery';
import 'jquery.easing';
import '../sass/style.scss';
import { Header } from './components/Header.js';
import { SearchBox } from './components/SearchBox.js';
import { Product } from './components/Product.js';
import { Blog } from './components/Blog.js';
import { mediaQuery } from './utils/functions.js';

mediaQuery();
SearchBox();
Product();
Header();
Blog();

function requireAll(r){
  r.keys().forEach(r);
}


const resizeHandler = (e)=>{
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('load', resizeHandler);
window.addEventListener('resize', resizeHandler);





