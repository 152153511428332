export function Blog() {

  const filter = document.querySelector('.blog-page .filter form');

  if(filter){
    filter.addEventListener('submit', (e) => {

      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      // get value of name "tag"
      console.log(formData)
      const tag = formData.get('tag');
      // redirect to the tag page
      if(tag){
        window.location.href = tag;
      }

    });
  }

}