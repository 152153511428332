export function Header() {


  const siteNav = document.querySelector('.site-nav');
  const toggleNavButton = document.querySelector('.toggle-nav-button');

  if(siteNav && toggleNavButton){

    toggleNavButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget.classList.toggle('opened');
      siteNav.classList.toggle('opened');
    });

  }
  

}